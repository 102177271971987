<template>
  <v-dialog v-model="isOpen" max-width="490" scrollable persistent>
    <v-card
      v-if="doctor"
      :loading="isLoading"
      :disabled="isLoading"
      class="rounded-lg"
    >
      <v-card-title
        v-if="$vuetify.breakpoint.mobile && !showbooksucess"
        class="primary--text"
      >
        {{
          $t("APPOINTMENTS.add.step", {
            step: step,
            label: steps[step - 1].label
          })
        }}
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <v-stepper v-if="!showbooksucess" class="elevation-0" v-model="step">
          <v-stepper-header
            v-if="!$vuetify.breakpoint.mobile"
            style="background-color: rgb(242, 247, 253)"
            class="elevation-0"
          >
            <v-stepper-step :complete="step > 1" step="1">
              {{ steps[0].label }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2">
              {{ steps[1].label }}
            </v-stepper-step>
            <template v-if="steps.length > 2">
              <v-divider></v-divider>
              <v-stepper-step step="3">
                {{ steps[2].label }}
              </v-stepper-step>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card
                color="grey lighten-1"
                class="mb-12"
                height="200px"
              ></v-card>
            </v-stepper-content>

            <v-stepper-content class="pa-2" step="2">
              <div class="mb-2">
                <v-menu
                  v-model="datepicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      filled
                      prepend-inner-icon="mdi-calendar"
                      rounded
                      v-model="date"
                      single-line
                      hide-details
                      background-color="rgb(238, 240, 248)"
                      dense
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="date"
                    no-title
                    @input="datepicker = false"
                    :allowed-dates="val => availabledates.indexOf(val) !== -1"
                    :min="new Date().toISOString().substr(0, 10)"
                    @update:picker-date="monthChanged"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="mb-2">
                <v-chip-group v-model="selectedTime" column>
                  <v-chip
                    active-class="white--text yellow darken-3"
                    filter
                    :class="
                      $util.day.unix(time).format('HH') < 12
                        ? 'primary--text'
                        : 'white--text'
                    "
                    :color="
                      $util.day.unix(time).format('HH') < 12
                        ? 'blue lighten-4'
                        : $util.day.unix(time).format('HH') < 18
                        ? 'blue'
                        : 'blue darken-3'
                    "
                    :small="$vuetify.breakpoint.mobile"
                    label
                    class="rounded"
                    v-for="time in orderTime()"
                    :key="time"
                  >
                    {{ $moment.unix(time).format("hh:mm A") }}
                  </v-chip>
                </v-chip-group>
                <div
                  v-if="orderTime().length === 0 && !isLoading"
                  class="text-center font-weight-medium primary--text my-6"
                >
                  {{ $t("APPOINTMENTS.add.noavail") }}
                </div>
              </div>
              <div>
                <v-checkbox v-model="concent" color="primary" hide-details>
                  <template v-slot:label
                    ><div class="font-size-sm primary--text">
                      {{ $t("APPOINTMENTS.add.concent") }}
                    </div></template
                  ></v-checkbox
                >
              </div>
            </v-stepper-content>
            <template v-if="steps.length > 2">
              <v-stepper-content class="pa-2" step="3">
                <div class="text-h6 my-2 primary--text">
                  {{ $t("APPOINTMENTS.add.total") }}
                </div>
                <v-list-item class="dborder">
                  <v-list-item-content>
                    <v-list-item-title>
                      <div class="d-flex">
                        <div style="min-width:40px" class="font-weight-medium">
                          {{ $t("APPOINTMENTS.add.price") }}
                        </div>
                        <div>
                          {{ payable.doctor_price }}
                          {{ $util.getLocalCurrency() }}
                        </div>
                      </div>
                    </v-list-item-title>
                    <v-list-item-title>
                      <div class="d-flex">
                        <div style="min-width:40px" class="font-weight-medium">
                          {{ $t("APPOINTMENTS.add.tax") }}
                        </div>
                        <div>
                          {{ payable.tax }} {{ $util.getLocalCurrency() }}
                        </div>
                      </div>
                    </v-list-item-title>
                    <v-list-item-title>
                      <div class="d-flex">
                        <div style="min-width:40px" class="font-weight-medium">
                          {{ $t("APPOINTMENTS.add.fees") }}
                        </div>
                        <div>
                          {{ payable.fee }} {{ $util.getLocalCurrency() }}
                        </div>
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div
                      class="
                        primary
                        white--text
                        d-flex
                        justify-center
                        align-center
                        pa-2
                        rounded-lg
                      "
                    >
                      <div class="text-h5 font-weight-medium pushx">
                        {{ payable.total }}
                      </div>
                      <div style="font-size: 1.1rem">
                        {{ $util.getLocalCurrency() }}
                      </div>
                    </div>
                  </v-list-item-action>
                </v-list-item>
                <div class="text-h6 my-2 primary--text">
                  {{ $t("APPOINTMENTS.add.pymtOp") }}
                </div>
                <v-card
                  v-for="method in allowedTypes"
                  :key="method.id"
                  class="pa-0 mb-2 dborder"
                  flat
                  :style="
                    paymentType === method.code
                      ? 'border-color:#4CAF50 !important'
                      : ''
                  "
                  @click="paymentType = method.code"
                >
                  <v-card-subtitle
                    :class="paymentType === method.code ? 'green--text' : ''"
                    class="py-2"
                    ><v-icon
                      :color="paymentType === method.code ? 'green' : 'primary'"
                      size="25"
                      class="pushx"
                    >
                      {{ method.icon }} </v-icon
                    >{{ $util.getTranslation(method).name }}</v-card-subtitle
                  >
                  <v-expand-transition>
                    <v-card-text v-show="paymentType === method.code">
                      <template v-if="method.code === 'POS'">
                        <div
                          style="
                            display: flex;
                            width: 100%;
                            justify-content: center;
                            align-items: center;
                          "
                          v-if="!formready"
                        >
                          <v-progress-circular
                            color="primary"
                            indeterminate
                          ></v-progress-circular>
                        </div>

                        <form v-if="!showsuccess" id="payment-form">
                          <input
                            type="text"
                            v-model="cardholdername"
                            v-if="formready"
                            :placeholder="$t('APPOINTMENTS.add.cardholder')"
                            style="
                              height: 48px;
                              width: 100%;
                              margin-bottom: 15px;
                              border: 1px solid #d9d9d9;
                              border-radius: 7px;
                              font-weight: 400;
                              font-size: 16px;
                              padding: 0 16px 0 16px;
                            "
                          />
                          <div :key="paymentuid" id="card-container"></div>
                        </form>
                        <div
                          v-else
                          style="
                            min-height: 176px;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <lottie-animation
                            :animationData="
                              require('@/assets/animation/success.json')
                            "
                            :loop="false"
                            style="height: 176px"
                          />
                        </div>
                        <v-snackbar v-model="showerrormsg" timeout="2000">
                          <span style="text-danger"> {{ errormsg }}</span>

                          <template v-slot:action="{ attrs }">
                            <v-btn
                              color="white"
                              icon
                              v-bind="attrs"
                              @click="showerrormsg = false"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </template>
                        </v-snackbar>
                      </template>
                      <template v-else-if="method.code === 'BNK'">
                        <div
                          v-for="(bank, m) in method.bank_details.data"
                          :key="m"
                        >
                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t("PAYMENT.BANK.bankname") }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ bank.bank_name }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t("PAYMENT.BANK.accountname") }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ bank.account_name }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item dense>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t("PAYMENT.BANK.accountdetails") }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ bank.iban }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                        <v-list-item dense>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $t("APPOINTMENTS.add.ref") }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                              class="
                                primary--text
                                font-weight-medium font-size-h5
                              "
                            >
                              {{ paymentuid }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <div></div>
                        <v-alert
                          dense
                          type="warning"
                          class="ma-0 mt-3 text-body-1"
                        >
                          {{ $t("PAYMENT.BANK.NOTE1") }},
                          {{ $t("PAYMENT.BANK.NOTE2") }}
                        </v-alert>
                      </template>
                      <template v-else-if="method.code === 'CSH'"
                        >cash</template
                      >
                    </v-card-text>
                  </v-expand-transition>
                </v-card>
              </v-stepper-content>
            </template>
          </v-stepper-items>
        </v-stepper>
        <div
          v-else
          style="
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <lottie-animation
            :animationData="require('@/assets/animation/success.json')"
            :loop="false"
          />
        </div>
      </v-card-text>

      <v-card-actions
        v-if="!showbooksucess"
        style="border-top: solid 1px lightgray"
        class="pa-0"
      >
        <v-row style="width: 100%" class="pa-0 ma-0">
          <v-col class="pa-0 ma-0">
            <v-btn
              color="red darken-1"
              text
              class="elevation-0"
              block
              large
              @click="close()"
            >
              {{ $t("GENERAL.cancel") }}
            </v-btn>
          </v-col>
          <v-col
            class="pa-0 ma-0"
            :style="
              $root.lang === 'ar'
                ? 'border-right: solid 1px lightgray'
                : 'border-left: solid 1px lightgray'
            "
          >
            <v-btn
              :disabled="isActionDisabled"
              color="primary darken-1"
              class="elevation-0"
              text
              block
              large
              @click="handleAction"
            >
              {{
                steps.length === 2 && step === 1
                  ? $t("APPOINTMENTS.add.next")
                  : steps.length === 2 && step === 2
                  ? this.$t("APPOINTMENTS.add.book")
                  : step === 3
                  ? $t("PAYMENT.POS.ACTION", {
                      total: this.payable.total,
                      currency: $util.getLocalCurrency()
                    })
                  : this.$t("APPOINTMENTS.add.checkout")
              }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import LottieAnimation from "lottie-web-vue";
import ShortUniqueId from "short-unique-id";
export default {
  name: "BookAppointment",
  components: {
    LottieAnimation
  },
  data() {
    return {
      // state
      isOpen: false,
      isLoading: false,
      datepicker: false,
      concent: false,
      showbooksucess: false,
      showbookerror: false,
      step: 2,
      steps: [
        {
          step: 1,
          icon: "video",
          label: this.$t("APPOINTMENTS.add.steps.1")
        },
        {
          step: 2,
          icon: "calender",
          label: this.$t("APPOINTMENTS.add.steps.2")
        }
      ],
      // doctor
      doctor: null,
      schedule: null,

      date: this.$util
        .day()
        .format("YYYY-MM-DD")
        .toString(),
      // appiontment details
      type: "video",
      selectedTime: undefined,

      // payment info
      paymentTypes: [],
      paymentType: "POS",
      paymentuid: "",

      // pos
      card: null,
      payments: null,
      formready: false,
      showsuccess: false,
      showerrormsg: false,
      paymentstatus: "",
      errormsg: "",
      cardholdername: "",
      posResponse: null
    };
  },
  beforeMount() {},
  created() {
    // this.getItems();
    if (!window.Square) {
      // console.log("defining square in book");
      if (
        this.$util.data.settings.payment_env &&
        process.env.NODE_ENV === "production"
      ) {
        let square = document.createElement("script");
        square.setAttribute("type", "text/javascript");
        square.setAttribute("src", this.$root.ssdkurl_prod);
        document.head.appendChild(square);
      } else {
        let square = document.createElement("script");
        square.setAttribute("type", "text/javascript");
        square.setAttribute("src", this.$root.ssdkurl_test);
        document.head.appendChild(square);
      }
    }
  },
  computed: {
    isActionDisabled() {
      if (this.isLoading) return true;
      if (this.step === 2) {
        return !this.concent || this.selectedTime === undefined;
      }
      if (this.step === 3) {
        if (this.paymentType === "POS")
          return !this.formready || this.cardholdername.length == 0;
        else return false;
      }
      return false;
    },
    availabledates() {
      // console.log(this.schedule);
      return this.schedule !== null
        ? this.schedule
            .filter(day => {
              return !day.off && day.available > 0;
            })
            .map(day => {
              return day.date;
            })
        : [];
    },
    allowedTypes() {
      return this.paymentTypes.filter(type => {
        return type.active_for.includes(this.type) && type.status === "enabled";
      });
    },
    payable() {
      let payload = {
        doctor_price: 0,
        tax: 0,
        fee: 0,
        total: 0
      };
      if (this.doctor != null && !this.doctor.isFree) {
        payload.doctor_price =
          this.type === "video"
            ? this.doctor.local_video
            : this.doctor.local_clinic;
        payload.fee = (
          this.$util.data.settings.handling_fee * this.$util.getLocalRate()
        ).toFixed(2);
        payload.tax = (
          (this.$util.data.settings.tax / 100) *
          payload.doctor_price
        ).toFixed(2);
        payload.total = (
          parseFloat(payload.doctor_price) +
          parseFloat(payload.tax) +
          parseFloat(payload.fee)
        ).toFixed(2);
      }
      return payload;
    }
  },
  watch: {
    errormsg: function(e) {
      if (e != "") {
        this.showerrormsg = true;
      }
    },
    showerrormsg: function(flag) {
      if (!flag) this.errormsg = "";
    }
  },
  mounted() {
    this.$payments.getPaymentType().then(res => {
      this.paymentTypes = res;
      console.log("payment types", res);
    });
  },
  methods: {
    handleAction(e) {
      if (this.step === 1) {
        this.step = 2;
        return;
      } else if (this.step === 2) {
        if (this.steps.length === 2) {
          console.log("free book");
          this.book();
          return;
        } else this.step = 3;
      } else {
        console.log("pay & book");
        if (this.paymentType === "POS") this.handlePaymentMethodSubmission(e);
        else this.book();
      }
    },
    orderTime() {
      if (this.schedule === null) return [];

      let index = parseInt(this.date.split("-")[2]) - 1;
      let times =
        index >= this.schedule.length
          ? this.schedule[0].slots
          : this.schedule[parseInt(this.date.split("-")[2]) - 1].slots;

      let duration = this.doctor.call_duration
        ? this.doctor.call_duration
        : this.$util.getSettings().call_duration;
      return times
        .filter(time => {
          return (
            parseInt(time.unix) - parseInt(duration) * 60 >
            parseInt(
              this.$util
                .day()
                .format("X")
                .toString()
            )
          );
        })
        .map(time => {
          return time.unix;
        })
        .sort((a, b) => {
          if (parseInt(a) > parseInt(b)) return 1;
          else if (parseInt(a) < parseInt(b)) return -1;
          else return 0;
        });
    },
    async monthChanged(month) {
      console.log(
        "this.date",
        this.date,
        "condition",
        this.date.includes(month)
      );
      this.getAvailability(month);
    },
    async getAvailability(date) {
      // console.log("date to order", date);

      this.isLoading = true;
      await this.$util
        .getDS()
        .rpc.make("getDoctorSlots", {
          id: this.doctor.id,
          year: date.split("-")[0],
          month: date.split("-")[1],
          availability: this.doctor.availability,
          days_off: this.doctor.days_off,
          duration: this.doctor.call_duration
            ? this.doctor.call_duration
            : this.$util.getSettings().call_duration
        })
        .then(res => {
          // console.log("schedule", res);
          this.schedule = res;
          this.isLoading = false;
        });
    },
    open(doctor) {
      this.isOpen = true;
      this.paymentuid = new ShortUniqueId({ length: 6 })();
      this.doctor = doctor;

      if (!doctor.isFree && this.$util.isPaymentEnabled()) {
        this.perpareform();
        this.steps.push({
          step: 3,
          status: "pending",
          icon: "credit-card",
          label: this.$t("APPOINTMENTS.add.steps.3")
        });
      }

      this.getAvailability(this.date);
    },
    close() {
      this.isOpen = false;
      this.step = 2;
      this.steps = [
        {
          step: 0,
          status: "complete",
          icon: "video",
          label: this.$t("APPOINTMENTS.add.steps.1"),
          visible: true
        },
        {
          step: 1,
          status: "current",
          icon: "calender",
          label: this.$t("APPOINTMENTS.add.steps.2"),
          visible: true
        }
      ];
      this.doctor = null;
      this.date = this.$util
        .day()
        .format("YYYY-MM-DD")
        .toString();
      this.schedule = null;
      this.paymentType = "POS";
      this.selectedTime = undefined;
      this.concent = false;
      if (this.card != null) this.card.destroy();
      this.showsuccess = false;
      this.showerrormsg = false;
      this.cardholdername = "";
      this.posResponse = null;
      this.showbooksucess = false;
      this.showbookerror = false;
      this.payments = null;
      this.formready = false;
      this.paymentstatus = "";
      this.errormsg = "";
    },
    async book() {
      this.isLoading = true;
      let payload = {
        type: this.type,
        doctor: this.doctor.id,
        medical_file: this.$file.getCurrentFile().id,
        status: "pending",
        date: this.$util.day
          .unix(this.orderTime()[this.selectedTime])
          .utc()
          .format("YYYY-MM-DD")
          .toString(),
        time: this.$util.day
          .unix(this.orderTime()[this.selectedTime])
          .utc()
          .format("HH:mm")
          .toString(),
        date_time: this.$util.day
          .unix(this.orderTime()[this.selectedTime])
          .utc()
          .format("YYYY-MM-DD HH:mm")
          .toString()
      };
      if (this.type === "video")
        payload.video_room_id = this.$util.getDS().getUid();
      if (this.steps.length > 2) {
        payload.payment = {
          amount: (this.payable.total / this.$util.getLocalRate()).toFixed(2),
          method: this.paymentTypes.find(item => {
            return item.code === this.paymentType;
          }).id,
          udid: this.paymentuid,
          status: "pending"
        };
        if (this.paymentType === "POS") {
          (payload.status = "confirmed"),
            (payload.pos_details = this.posResponse);
        }
      }
      console.log("appointment payload", payload);
      await this.$app.createItem(payload).then(res => {
        if (res.status === 200) {
          this.isLoading = false;
          this.showbooksucess = true;

          console.log("result of booking", res.data);
          res.data.data.doctor = this.doctor;
          setTimeout(() => {
            this.$emit("closeparent", res.data.data);
            this.close();
          }, 1500);
        } else {
          this.isLoading = false;
          this.showbookerror = true;
        }
      });
    },
    // pos methods
    async initializeCard(payments) {
      this.card = await payments.card();

      await this.card.attach("#card-container");
      this.formready = true;
      return this.card;
    },
    async perpareform() {
      this.payments = window.Square.payments(
        this.getToken(),
        this.getLocation()
      );
      this.payments.setLocale(this.$root.lang);
      try {
        this.card = await this.initializeCard(this.payments);
      } catch (e) {
        console.error("Initializing Card failed", e);
        return;
      }
    },
    async handlePaymentMethodSubmission(e) {
      e.preventDefault();

      try {
        // disable the submit button as we await tokenization and make a
        // payment request.
        const token = await this.tokenize(this.card);
        let verificationToken;
        verificationToken = await this.verifyBuyer(token);

        // console.log("Verification Token:", verificationToken);
        await this.createPayment(token, verificationToken);
      } catch (e) {
        console.error(e.message);
      }
    },
    async createPayment(token, vtoken) {
      this.isLoading = true;
      var locationId = this.getLocation();
      const body = {
        locationId,
        sourceId: token,
        varificationToken: vtoken,
        uuid: new ShortUniqueId().stamp(32),
        amount:
          (this.payable.total / this.$util.getLocalRate()).toFixed(2) * 100,
        fee: 0,
        user_id: JSON.parse(localStorage.userInfo).id,
        live: JSON.parse(localStorage.SETTINGS).payment_env
      };
      // console.log("creating payment");
      await this.$api
        .post("custom/payment/dopayment/" + this.paymentuid, body)
        .then(({ data }) => {
          // console.log("payment result", data);
          // console.log("response code", data.data.response_code);
          if (data.data.response_code == 200) {
            this.paymentstatus = "success";
            this.isLoading = false;
            this.showsuccess = true;
            this.posResponse = data.data.square_response;
            this.book();
          } else if (data.data.response_code == 400) {
            this.paymentstatus = "fail";
            this.isLoading = false;
            this.errormsg = data.data.square_response.errors[0].code;
          }
        })
        .catch(error => {
          this.error = error;
        });
    },
    getToken() {
      if (
        this.$util.data.settings.payment_env &&
        process.env.NODE_ENV === "production"
      ) {
        return this.$root.stoken_prod;
      } else return this.$root.stoken_test;
    },
    getLocation() {
      if (
        this.$util.data.settings.payment_env &&
        process.env.NODE_ENV === "production"
      ) {
        return this.$root.sloc_prod;
      } else return this.$root.sloc_test;
    },
    async verifyBuyer(token) {
      const verificationDetails = {
        amount:
          (this.payable.total / this.$util.getLocalRate()).toFixed(2) * 100 +
          "",
        /* collected from the buyer */
        billingContact: {
          familyName: this.cardholdername.split(" ")[
            this.cardholdername.split(" ").length - 1
          ],
          givenName: this.cardholdername.split(" ")[0],
          email: this.$user.getCurrentUser().email
        },
        currencyCode: "GBP",
        intent: "CHARGE"
      };
      // console.log("var details", verificationDetails);

      const verificationResults = await this.payments.verifyBuyer(
        token,
        verificationDetails
      );
      return verificationResults.token;
    },
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === "OK") {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
        }
        throw new Error(errorMessage);
      }
    }
  }
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
.v-application--wrap {
  min-height: 0px !important;
}
.activebtn {
  border-style: solid;
  border-color: #d2e1f2 !important;
  background: white !important;
  border-width: 4px !important;
  color: #0971ce !important;
}
</style>
<style scoped>
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) >>> .v-btn.v-btn {
  border-color: #d2e1f2 !important;
  border-width: 0px;
}

.v-btn:before {
  background-color: transparent !important;
}
.theme--light >>> .v-navigation-drawer__border {
  background-color: transparent !important;
}
</style>
