<template>
  <v-app
    style="width: 100% !important"
    :style="$root.lang == 'ar' ? merkazi : ''"
  >
    <div
      :style="$vuetify.breakpoint.mobile ? 'height:200px' : 'height:250px'"
      class="profilecover"
    >
      <div style="position: absolute;top: 10px;left: 10px;right: 10px;">
        <v-btn @click="$router.go(-1)" icon elevation="0"
          ><v-icon color="white"
            >mdi-chevron-{{ $root.lang === "ar" ? "right" : "left" }}</v-icon
          ></v-btn
        >
      </div>
      <v-row style="align-self: end;">
        <v-col class="my-0 py-0" v-if="!$vuetify.breakpoint.mobile" cols="3">
        </v-col>
        <v-col class="my-0 py-0">
          <v-list-item v-if="doctor">
            <v-list-item-avatar
              color="white"
              :size="$vuetify.breakpoint.mobile ? '65' : '80'"
            >
              <v-img
                :lazy-src="
                  $util.asseturl + doctor.doctor_id.avatar.private_hash
                "
                v-if="doctor.doctor_id.avatar != null"
                :src="$util.asseturl + doctor.doctor_id.avatar.private_hash"
              ></v-img>
              <div
                v-else
                style="text-transform: uppercase; width: 100%"
                class="primary--text font-size-h4"
              >
                {{ doctor.doctor_id.first_name.charAt(0) }}
              </div>
            </v-list-item-avatar>
            <v-list-item-content class="">
              <v-list-item-title class="white--text text-h4">
                Dr. {{ doctor.doctor_id.first_name }}
                {{ doctor.doctor_id.last_name }}
              </v-list-item-title>
              <v-list-item-subtitle class="white--text text-h6">
                {{
                  $util.getTranslation(
                    $department.data.departments.data.find(d => {
                      return d.id === doctor.department.id;
                    })
                  ).name
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-tabs height="50px" background-color="transparent" dark grow>
            <v-tab @click="tab = 1">{{ $t("PROFILE.tabs.profile") }}</v-tab>
            <v-tab @click="tab = 2">{{ $t("PROFILE.tabs.posts") }}</v-tab>
          </v-tabs>
        </v-col>
        <v-col class="my-0 py-0" v-if="!$vuetify.breakpoint.mobile" cols="3">
        </v-col>
      </v-row>
    </div>

    <v-main>
      <v-container v-if="doctor">
        <v-row v-if="tab === 1">
          <v-col v-if="!$vuetify.breakpoint.mobile" cols="3"> </v-col>
          <v-col class="mt-0 pt-0 pb-12">
            <v-card class="transparent  px-3 mb-2" flat>
              <v-slide-y-transition>
                <v-row
                  style="gap: 5px;flex-wrap: nowrap;"
                  :style="
                    $vuetify.breakpoint.mobile ? 'width: 102%;' : 'width: 101%;'
                  "
                  v-show="!isLoading"
                >
                  <v-col
                    class="d-flex align-center justify-space-between pa-2 py-5 rounded-lg yellow darken-3 white--text"
                    cols="3"
                  >
                    <unicon
                      :class="$vuetify.breakpoint.mobile ? 'pushx' : 'pushxl'"
                      name="favorite"
                      :fill="'#FFFFFF'"
                      height="25px"
                      width="25px"
                    ></unicon>
                    <span class="text-h6">{{ rating }}</span>
                  </v-col>
                  <v-col
                    class="d-flex align-center justify-space-between pa-2 py-5 rounded-lg yellow darken-3 white--text"
                    cols="3"
                    ><unicon
                      :class="$vuetify.breakpoint.mobile ? 'pushx' : 'pushxl'"
                      name="stethoscope"
                      :fill="'#FFFFFF'"
                      height="25px"
                      width="25px"
                    ></unicon>
                    <span class="text-h6">{{ appointments }}</span>
                  </v-col>
                  <v-col
                    class="d-flex align-center justify-space-between pa-2 py-5 rounded-lg yellow darken-3 white--text"
                    cols="3"
                    ><unicon
                      :class="$vuetify.breakpoint.mobile ? 'pushx' : 'pushxl'"
                      name="comment-redo"
                      :fill="'#FFFFFF'"
                      height="25px"
                      width="25px"
                    ></unicon>
                    <span class="text-h6">{{ answers }}</span>
                  </v-col>
                  <v-col
                    class="d-flex align-center justify-space-between pa-2 py-5 rounded-lg yellow darken-3 white--text"
                    cols="3"
                  >
                    <unicon
                      :class="$vuetify.breakpoint.mobile ? 'pushx' : 'pushxl'"
                      name="document-layout-left"
                      :fill="'#FFFFFF'"
                      height="25px"
                      width="25px"
                    ></unicon>
                    <span class="text-h6">{{ posts }}</span>
                  </v-col>
                </v-row>
              </v-slide-y-transition>
            </v-card>
            <v-card
              class="dborder rounded mb-2"
              style="background-color: white"
              flat
            >
              <v-card-title class="primary--text">{{
                $t("MF.summery.summery.title")
              }}</v-card-title>
              <v-card-text>
                {{
                  $t("PROFILE.summery", {
                    name: `${doctor.doctor_id.first_name} ${doctor.doctor_id.last_name}`,
                    year:
                      parseInt(
                        $util
                          .day()
                          .format("YYYY")
                          .toString()
                      ) - parseInt(doctor.practice_started.split("-")[0]),
                    langcount: doctor.languages_spoken.length,
                    langs: doctor.languages_spoken.map(l => {
                      return l.languages_id.name;
                    }),
                    bio: doctor.bio
                  })
                }}
              </v-card-text>
            </v-card>
            <v-card class="rounded" style="background-color: transparent" flat>
              <v-card-title class="primary--text">{{
                $t("PROFILE.review.title")
              }}</v-card-title>
              <v-list color="transparent" v-if="reviews.length > 0">
                <v-list-item
                  two-line
                  class="dborder rounded mb-2"
                  style="background-color: white"
                  v-for="review in reviews"
                  :key="review.id"
                >
                  <v-list-item-avatar color="primary" class="white--text">
                    D
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-rating readonly :value="review.rating" />
                    </v-list-item-title>
                    <div>
                      {{ review.comment }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <div
                class="d-flex justify-center align-center"
                v-else-if="isLoading"
              >
                <v-progress-circular indeterminate color="primary" size="20" />
              </div>
              <div class="text-h6 text-center" v-else>
                {{ $t("PROFILE.review.noreviews") }}
              </div>
            </v-card>
          </v-col>
          <v-col v-if="!$vuetify.breakpoint.mobile" cols="3"> </v-col>
        </v-row>

        <v-row v-if="tab === 2">
          <v-col v-if="!$vuetify.breakpoint.mobile" cols="3"> </v-col>
          <v-col cols="12" md="6" lg="6" xl="6" class="pt-0 mt-0">
            <Feed :owner="doctor.doctor_id" />
          </v-col>
          <v-col v-if="!$vuetify.breakpoint.mobile" cols="3"> </v-col>
        </v-row>
        <v-row style="position:fixed;bottom:10px;width: 100vw;">
          <v-col v-if="!$vuetify.breakpoint.mobile" cols="3"> </v-col>
          <v-col cols="12" md="6" lg="4" xl="5" class="pt-0 mt-0">
            <v-btn
              class="mt-2"
              block
              rounded
              @click="$refs.book.open(doctor)"
              color="green"
              dark
              elevation="0"
              >{{
                $t("PROFILE.book", {
                  name: `${doctor.doctor_id.first_name} ${doctor.doctor_id.last_name}`
                })
              }}
            </v-btn>
          </v-col>
          <v-col v-if="!$vuetify.breakpoint.mobile" cols="3"> </v-col>
        </v-row>
      </v-container>
    </v-main>
    <BookAppointment ref="book" />
  </v-app>
</template>
<script>
import BookAppointment from "@/view/pages/appointment/comp/Book.vue";
import Feed from "@/view/pages/social/Feed.vue";
export default {
  name: "DoctorProfile",
  components: { BookAppointment, Feed },

  data() {
    return {
      doctor: null,
      rating: 0,
      answers: 0,
      posts: 0,
      appointments: 0,
      reviews: [],
      isLoading: true,
      tab: 1
    };
  },
  created() {
    this.doctor = this.$doctor.getLocalDocs().find(d => {
      return d.doctor_id.external_id === this.$route.params.id;
    });
    this.$doctor.getProfileInfo(this.doctor).then(res => {
      console.log("result of info", res);
      this.answers = res.answers.length;
      this.appointments = res.apps.length;
      this.posts = res.posts.length;
      this.rating =
        res.rating.length === 0
          ? 3
          : res.rating.reduce((a, b) => {
              // console.log("a", a);
              // console.log("b", b);
              return { rating: a.rating + b.rating };
            }).rating / res.rating.length;
      this.reviews = res.rating;
      this.isLoading = false;
    });
  },
  computed: {},
  mounted() {},
  destroyed() {},
  methods: {}
};
</script>
